<template>
    <modal ref="editor">
        <editor
            :title="editor.title"
            :errors="editor.errors"
            :item="editor.item"
            :fields="editor.fields"
            :id="editor.id"
            @save="saveItem"
            @close="closeEditor"
        ></editor>
    </modal>
    <div class="content">
        <div class="title">Менеджеры</div>
        <listing
            :loading="loading"
            :items="listing.items"
            :fields="listing.fields"
            :pagination="listing.pagination"
            @change-page="changePage"
            @add-item="addItem"
            @edit-item="editItem"
            @remove-item="removeItem"
            @change-query="changeQuery"
            @apply-filters="loadItems"
            @close-filters="loadItems"
        ></listing>
        <div v-if="loading">Загрузка</div>
    </div>
</template>

<script>
import { ManagersService } from "../services/managers.service";
import Listing from "../components/listing/Listing";
import Modal from "../components/Modal";
import Editor from "../components/editor/Editor";

export default {
    name: "Clients",
    components: {Editor, Modal, Listing},
    methods: {
        loadItems() {
            this.loading = true;
            this.service.get(this.listingParams).then(response => {
                this.listing.items = response.data;
                this.listing.pagination = response.pagination;
                this.listing.fields = response.listing;
                this.editor.fields = response.editor;
                this.loading = false;
            });
        },
        changePage(page) {
            this.listing.page = page;
        },
        addItem() {
            this.editor.title = 'Добавить менеджера';
            this.editor.item = {};
            this.editor.id = null;
            this.$refs.editor.show();
        },
        editItem(id) {
            this.editor.id = id;
            this.service.one({
                filters: {
                    id: this.editor.id
                }
            }).then(response => {
                this.editor.title = 'Редактировать менеджера';
                this.editor.item = response.data;
                this.$refs.editor.show();
            });
        },
        removeItem(id) {
            this.service.remove({
                filters: {
                    id: id
                }
            }).then(response => {
                if (response.success) {
                    this.loadItems();
                }
            });
        },
        saveItem(response) {
            this.service.save(response).then(response => {
                if (response.success === true) {
                    this.loadItems();
                    this.$refs.editor.hide();
                    this.editor.id = null;
                } else {
                    this.editor.errors = response.errors;
                }
            });
        },
        closeEditor() {
            this.$refs.editor.hide();
        },
        changeQuery(query) {
            this.listing.query = query;
        }
    },
    computed: {
        listingParams() {
            let params = {
                page: this.listing.page,
                filters: []
            };
            if (this.listing.query) {
                params.filters.fio = {
                    operator: 'LIKE',
                    value: '%' + this.listing.query + '%'
                }
            }

            return params;
        },
    },
    created() {
        this.loadItems();
    },
    data() {
        return {
            service: ManagersService,
            loading: true,
            listing: {
                items: [],
                fields: [],
                page: 1,
                pagination: {},
                query: ''
            },
            editor: {
                id: null,
                item: {},
                title: '',
                fields: [],
                errors: [],
            }
        }
    }
}
</script>

<style scoped>

</style>