import { reactive } from "vue";
import HttpService from "./http.service";

export const ManagersService = reactive({
    state: [],
    autocomplete(data) {
        return HttpService.get('managers/autocomplete', data);
    },
    get(data) {
        return HttpService.get('managers/all', data);
    },
    one(data) {
        return HttpService.get('managers/one', data);
    },
    save(data) {
        return HttpService.post('managers/save', data);
    },
    remove(data) {
        return HttpService.post('managers/remove', data);
    }
})